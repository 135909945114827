import React, { Component } from 'react';
import axios from 'axios';
import config from '../config/config'

import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import { browserName, browserVersion } from "react-device-detect";
const headers = {
  'Content-Type': 'application/json'
};

export default class login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      ip: '',
      browser: ''
    }
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }


  componentDidMount() {
    this.getLocation()
   
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  //===========================================  login activity  ================================

  getLocation = (e) =>{
    axios.get(`https://ipapi.co/json/?__cf_chl_jschl_tk__=pmd_TjdfQUFNDtY8uWKDkPep3E7Aj6nEL124fHJeLOsu8Gc-1631799864-0-gqNtZGzNAhCjcnBszQh9`).then(result => {
      this.setState({
        ip: result.data.ip
      })
      console.log('sdfsdf',this.state.ip);
    })
  }

  //================================================  Login API integrate  =============

  async submitForm(e) {
    e.preventDefault()
    const data = this.state
    data.ip = this.state.ip
    data.browser = browserName
    axios.post(`${config.apiUrl}/login`, data, { headers })
      .then(response => {
        if (response.data.success === true) {

          toast.success('Login Successfully.', {
            style: {
              border: '1px solid #713200',
              padding: '10px',
              color: 'green',
            },
            iconTheme: {
              primary: 'orange',
              secondary: '#FFFAEE',
            },
          });
          Cookies.set('loginSuccessAdmin', JSON.stringify(response.data));

          setTimeout(() => {
            window.location.href = `${config.baseUrl}dashboard`
          }, 1000);

        }
        else if (response.data.success === false) {

        }
      })

      .catch(err => {
        toast.error(err.response.data.msg);
            })
  }


  render() {
    return (
      <>


        <div className="hold-transition login-page">
          <div className="login-box">
            <div className="login-logo">
              <a href="index.html"><img src="dist/img/ABC.jpeg" style={{ maxHeight: "75px", width: "260px" }} alt="" /></a>
              <h5 style={{ color: "#ff5722" }}>Admin</h5>
            </div>

            <div className="card">
              <div className="card-body login-card-body">
                <p className="login-box-msg">Sign in to start your session</p>

                <form onSubmit={this.submitForm}>
                  <Toaster
                    position="top-center"
                    reverseOrder={false}
                  />
                  <div className="input-group mb-3">
                    <input type="email" className="form-control" placeholder="Email" value={this.state.email}
                      onChange={this.onChange} name="email" />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input type="password" className="form-control" placeholder="Password" value={this.state.password}
                      onChange={this.onChange} name="password" />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>

                  <div className="social-auth-links text-center mb-3">

                    <button type="submit" className="btn btn-block btn-primary">

                      Login
                    </button>

                  </div>
                </form>






              </div>

            </div>
          </div>
        </div>
      </>

    )
  }
}