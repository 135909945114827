import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import config from '../config/config'
import Sidebar from '../directives/sidebar'
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie';
import ReactDatatable from '@ashvin27/react-datatable'
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class faqs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            user_id: '',
            question: '',
            answer: '',
            faq_list: [],
            data: [],
            index : 0
             
        };
        this.faqDelete = this.faqDelete.bind(this);
        this.loginData = (!Cookies.get('loginSuccessAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessAdmin'));
        this.handleChange1 = this.handleChange1.bind(this);
    

        this.columns = [
            {
                key: '#',
                text: 'Sr. No.',
                sortable: true,
                cell: (row, index) => index + 1
              },
              {
                key: "question",
                text: "Question",
                sortable: true
            },
            {
                key: "answer",
                text: "Answer",
                sortable: true
            },
         
           {
                key: "user_status",
                text: "Action",
                sortable: true,
                cell: (item) => {
                    return (
                        <>
                       <a class="btn btn-danger btn-sm"  data-original-title="Close" data-toggle="tooltip" onClick={this.faqDelete.bind(this, item)}>
                                                        <i class="fas fa-trash">
                                                        </i>&nbsp;
                                                        Delete
                                                    </a>
                        </>
                        
                    );
                }
            },
           
          
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false
            }
        } 


        }

        componentDidMount() {
            
            this.faqList();
        }
    
        handleChange1 = e => {
          
            this.setState({
                [e.target.name]: e.target.value
            })
        }

    //  ============================================Get FAQ List====================================     

            async faqList() {
               
                await axios.get(`${config.apiUrl}faqlist`, {},)
                    .then(result => {
                        const data = result.data;
                        console.log(result.data);
                        if (result.data.success === true) {
                            this.setState({
                                faq_list: result.data.response,
                                pageCount: Math.ceil(data.length / this.state.perPage),
                                         
                            })
                           }
        
                        else if (result.data.success === false) {
        
                        }
                    })
        
                    .catch(err => {
                    })
            }

    //  ============================================Delete FAQ ====================================         

        faqDelete(id) {
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to delete this Que and Ans!.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () =>
            axios.post(`${config.apiUrl}/faqdelete`, {  'id': id.id })
                .then(result => {
    
                    if (result.data.success === true) {
                        toast.success(result.data.msg, {
                          
                        });
                        this.componentDidMount();
    
                    }
    
                    else if (result.data.success === false) {
    
                    }
                })
    
                .catch(err => {
                })
            },
            {
                label: 'No',
            }
        ]
    });
        }


   //  ============================================Add FAQ ====================================  

        handleSubmit = async (event) => {
            event.preventDefault();
           axios.post(`${config.apiUrl}/faqadd`,{'question':this.state.question,'answer':this.state.answer})
                .then(result => {
                    toast.success(result.data.msg, {
                                },
                               );
                   this.setState({
                        question: '',
                        answer: '',
                       })
                    this.faqList();
                }).catch(err => {
                        toast.error(err.data?.msg, {
                    }, setTimeout(() => {
                    }, 500));
                });
        }
    
        
        
        




    render() {
        return (
  <>

<Header/>
{/* --------------------------------------------------------------------------------------------------- */}
<Sidebar/>
<Toaster
  position="top-center"
  reverseOrder={false}
/>
<div className="content-wrapper">

<section className="content">


<div className="card">
  <div className="card-header">
   <button type='button' data-toggle="modal" data-target="#responsive-modal1" className="btn btn-primary pb-4">Add </button>
  <hr/>

  <div className="card-body p-0">
    <table className="table table-striped projects table-responsieve-sm">
       
       
        <ReactDatatable
                     config={this.config}
                     records={this.state.faq_list}
                     columns={this.columns}
                                         /> 
      
    </table>
  </div>
  </div>
</div>
{/* =======================================================Add============================================= */}
<div id="responsive-modal1" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display: "none" }}>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    {/* <div class="modal-header">
														<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
														<h5 class="modal-title">Modal Content is Responsive</h5>
													</div> */}
                                    <div class="modal-body">
                                        <form action="#">
                                            <h6 className="txt-dark capitalize-font"><i className="zmdi zmdi-info-outline mr-10"></i>Add Que and Ans</h6>
                                            <hr className="light-grey-hr" />
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="control-label mb-10">Question</label>
                                                        <textarea type="text" onChange={this.handleChange1} name="question" className="form-control" placeholder="Type New Question" value={this.state.question} />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="control-label mb-10">Answer</label>
                                                        <textarea type="text"  onChange={this.handleChange1} name="answer" className="form-control" placeholder="Type New Answer" value={this.state.answer}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-actions">
                                                {/* <button type="submit" onClick={this.handleSubmit} className="btn btn-success btn-icon left-icon mr-10 pull-left"> <i className="fa fa-check"></i> <span>save</span></button> */}
                                                <div className="clearfix"></div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="modal-footer pt-0">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>

                                        <button type='submit' onClick={this.handleSubmit} data-dismiss="modal" className="btn btn-primary">Add</button>

                                    </div>
                                </div>
                            </div>
                        </div>


</section>
</div>

<Footer/>

</>
)
}
}