import React, { Component } from 'react';
import axios from 'axios';
import config from '../config/config'
import Sidebar from '../directives/sidebar'
import Header from '../directives/header'
import Footer from '../directives/footer'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
const headers = {
  'Content-Type': 'application/json'
};

export default class changepassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentPassword: '',
      password: '',
      password2: ''
    }

    this.loginData = (!Cookies.get('loginSuccessAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessAdmin'));
    this.adminchangePassword = this.adminchangePassword.bind(this);
  }

  componentDidMount() {
    // this.adminchangePassword()

  }



  handleChange = e => {

    this.setState({
      [e.target.name]: e.target.value
    })
  }

  async adminchangePassword(e) {
    e.preventDefault()
    await axios({
      method: 'post',
      url: `${config.apiUrl}changepassword`,
      data: { "id": this.loginData?.data?.user_id, "email": this.loginData?.data?.user_email, "currentPassword": this.state.currentPassword, "password": this.state.password, "password2": this.state.password2 }
    })
      //
      .then(result => {

        if (result.data.success === true) {
          toast.success('Password Change Successfully.', {
            style: {
              border: '1px solid #713200',
              padding: '10px',
              color: 'green',
            },
            iconTheme: {
              primary: 'orange',
              secondary: '#FFFAEE',
            },
          });
          window.location.href = `${config.baseUrl}dashboard`
        }
        else if (result.data.success === false) {
          toast.error(result.data.msg, {

          });
        }

      }).catch(error => {
        console.log(error);

      });
  }




  render() {
    return (
      <>
        <Header />
        {/* --------------------------------------------------------------------------------------------------- */}
        <Sidebar />

        <div className="hold-transition login-page">
          <div class="login-box">
            <div class="card card-outline card-primary">
              <div class="card-header text-center">

                <a href="index.html"><img src="dist/img/ABC.jpeg" style={{ maxHeight: "75px", width: "260px" }} alt="" /></a>
              </div>
              <div class="card-body">
                <p class="login-box-msg">You are only one step a way from your new password, recover your password now.</p>
                <form >
                  <Toaster
                    position="top-center"
                    reverseOrder={false}
                  />
                  <div className="input-group mb-3">
                    <input type="password" className="form-control" placeholder="Old Password" onChange={this.handleChange} name="currentPassword" value={this.state.currentPassword} />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>

                  <div className="input-group mb-3">
                    <input type="password" className="form-control" placeholder="New Password" onChange={this.handleChange} name="password" value={this.state.password} />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input type="password" className="form-control" placeholder="Confirm Password" onChange={this.handleChange} name="password2" value={this.state.password2} />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button type="submit" onClick={this.adminchangePassword} className="btn btn-primary btn-block">Change password</button>
                    </div>

                  </div>
                </form>


              </div>

            </div>
          </div>



        </div>
        <Footer/>
      </>
    )
  }
}