import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import config from '../config/config'
import Sidebar from '../directives/sidebar'
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie';
import ReactDatatable from '@ashvin27/react-datatable'
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
// import { Tabs, Tab } from 'react-tab-view'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default class users extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            user_id: '',
            user_list: [],
            activitydetail : [],
            data: [],
            index : 0
                    };
        this.loginData = (!Cookies.get('loginSuccessAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessAdmin'));
  

        this.columns = [
            {
                key: '#',
                text: 'Sr. No.',
                sortable: true,
                cell: (row, index) => index + 1
              },
              {
                key: "first_name",
                text: "First Name",
                sortable: true
            },
            {
                key: "last_name",
                text: "Last Name",
                sortable: true
            },
           {
                key: "email",
                text: "Email",
                sortable: true
            },
            {
                key: "is_email_verify",
                text: "Email Verify",
                sortable: true,
                cell: (item) => {
                    return (
                        <>
                            {(item.is_email_verify === 0) ? 'Not Verified' : "Verified"}
                            
                           
                        </>
                    );
                }
            },
           {
                key: "user_status",
                text: "Action",
                sortable: true,
                cell: (item) => {
                    return (
                        <>
                        
                     <div className="btn-group">
                    <button type="button" className="btn btn-info">Action</button>
                    <button type="button" className="btn btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                      <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu" role="menu" >
                    {item.deactivate_account === 1 ?
                      <a className="dropdown-item" onClick={this.useractionAPI.bind(this, item)}>Un-Block</a>
                     :
                     <a className="dropdown-item" onClick={this.useractionAPI.bind(this, item)}>Block</a>}
                     
                      <a className="dropdown-item" href={`${config.baseUrl}userinfo/${item.id}`} onClick={this.useractivity.bind(this, item)}  >User Info</a>
                     
                    </div>
                  </div>


                        </>
                        
                    );
                }
            },
          
          
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false
            }
        } 
       }

        componentDidMount() {
            if (!Cookies.get('loginSuccessAdmin')) {
                window.location.href = `${config.baseUrl}`
                return false;
            }
                    this.userList()
                    this.useractivity()
        }
    
        handleChange1 = e =>{
            this.setState({
                [e.target.name] : e.target.value 
             })
            }  
            
         
          
// =======================================User List============================================================
            async userList() {
               
                await axios.get(`${config.apiUrl}getAllUserList`, {},)
                    .then(result => {
                        const data = result.data;
                        // const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                       
                        console.log(result.data);
                        if (result.data.success === true) {
                            this.setState({
                                user_list: result.data.response,
                                pageCount: Math.ceil(data.length / this.state.perPage),
                                         
                            })
                           }
                        else if (result.data.success === false) {
                       }
                    })
        
                    .catch(err => {
                    })
            }

//  =============================================User Block and Un-Block===========================================================           

            useractionAPI(id) {
              
                confirmAlert({
                    title: 'Confirm to submit',
                    message: id.deactivate_account === 1 ? 'Are you sure to Un-Block him.':
                    'Are you sure to Block him.',
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () =>
                                axios.post(`${config.apiUrl}/deactivate`, { 'email': id.email })
                                    .then(result => {
        
                                        if (result.data.success === true) {
                                            toast.success(result.data.msg, {
                                       
                                            });
                                               this.componentDidMount();
                                                }
                                     else if (result.data.success === false) {
                                               }
                                    })
                                   .catch(err => {
                                    })
                        },
                        {
                            label: 'No',
                        }
                    ]
                });
               }

// ================================================User Login Activity =========================================================

async useractivity(user_id) {
    console.log("user_id",user_id);
    axios.post(`${config.apiUrl}userloginactivity`,{'user_id':this.state.user_id})
       .then(response => {
         // console.log('abc');
          if (response.data.success === true) {
           
             this.setState({
                activitydetail: response.data?.response,
                           })
          }
          else if (response.data.success === false) {
          }
       }).catch(err => {
       });
    }




//  ======================================================================================================    
   render() {
 
        return (
  <>

<Header/>

<Sidebar/>
<div class="content-wrapper" >
<section className="content">
      {/* <div className="container-fluid"> */}


<div className="card">
  <div className="card-header">
    <h3 className="card-title">Users</h3>
   <br/>
    <hr/>
  
  <div className="card-body p-0">
    <table className="table table-striped projects table-responsive-sm">
      
        
        <ReactDatatable
                       config={this.config}
                       records={this.state.user_list}
                       columns={this.columns}
                                          /> 
        
    </table>
    </div>
  </div>
</div>
{/* </div> */}
</section>
</div>

<Footer/>

</>
)
}
}