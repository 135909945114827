import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import config from '../config/config'
import Sidebar from '../directives/sidebar'
import Header from '../directives/header'
import Cookies from 'js-cookie';
import ReactDatatable from '@ashvin27/react-datatable'
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';

export default class adminactivity extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            user_id: '',
            useractivity_list: [],
            data: [],
            index : 0
                    };
        this.loginData = (!Cookies.get('loginSuccessAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessAdmin'));
        this.useractivity = this.useractivity.bind(this);
    

        this.columns = [
            {
                key: '#',
                text: 'Sr. No.',
                sortable: true,
                cell: (row, index) => index + 1
              },
               {
                key: "ip",
                text: "IP Address",
                sortable: true
            },
            {
                key: "browser",
                text: "Browser",
                sortable: true
            },
            {
                key: "activity_time",
                text: "Activity Time",
                sortable: true,
                cell: (item) => {
                    return (
                        <>
                          <span className="">{(item?.activity_time?.replace('.000Z', ''))?.replace('T', ' ')}</span>
                        </>
                    );
                }
            },
            ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false
            }
        } 
        }

        componentDidMount() {
          if (!Cookies.get('loginSuccessAdmin')) {
            window.location.href = `${config.baseUrl}`
            return false;
        }
                   this.useractivity()
        }
    
        handleChange1 = e =>{
           
            this.setState({
                [e.target.name] : e.target.value 
             })
            }  

   
// =======================================User Activity========================================
           
async useractivity(id) {
         await axios({
            method: 'post', 
            url: `${config.apiUrl}userloginactivity`,
            data:{"user_id":this.loginData?.data?.user_id}
         })
           .then(result => {
         
                if (result.data.success === true) {
                    this.setState({
                        useractivity_list: result.data.response,
                                  })
              } 
                else if (result.data.success === false) {
                  toast.error(result.data.msg, {
                  
                     });
              }
     
             }).catch(error => {
           
            
         });
      }


    render() {
        return (
  <>

<Header/>
{/* --------------------------------------------------------------------------------------------------- */}
<Sidebar/>
<div className="content-wrapper">

<section className="content">


<div className="card">
  <div className="card-header">
    <b className="card-title">Admin Activity</b>
    <br/>
   <hr/>
  <div className="card-body p-0">
    <table className="table table-striped projects table-responsive-sm">
      
        <tbody>
        <ReactDatatable
                       config={this.config}
                       records={this.state.useractivity_list}
                       columns={this.columns}
                                          /> 
        </tbody>
    </table>
  </div>
  </div>
</div>


</section>
</div>

<footer className="main-footer">
  <strong>Copyright &copy; 2014-2021 <a href="https://espsofttech.tech/abc-wallet/" target="_blank">ABC Wallet</a>.</strong>
  All rights reserved.
 
</footer>

</>
)
}
}