import logo from './logo.svg';
import './App.css';
//==================================  Import all dependencies  ============================

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import config from './config/config'
import login from './Components/login'
import home from './Components/home'
import users from './Components/users'
import changepassword from './Components/changepassword'
import forgotpassword from './Components/forgotpassword'
import faqs from './Components/faqs'
import contactus from './Components/contactus'
import adminactivity from './Components/adminactivity'
import userinfo from './Components/userinfo'
import transaction from './Components/transaction'

function App() {
  return (
    <BrowserRouter>
      <div>
        {/* <Menu /> */}
        <Switch>
          <Route path={`${config.baseUrl}`} exact component={login} />
          <Route path={`${config.baseUrl}dashboard`} exact component={home} />
          <Route path={`${config.baseUrl}changepassword`} exact component={changepassword} />
          <Route path={`${config.baseUrl}forgotpassword`} exact component={forgotpassword} />
          <Route path={`${config.baseUrl}users`} exact component={users} />
          <Route path={`${config.baseUrl}contactus`} exact component={contactus} />
          <Route path={`${config.baseUrl}faqs`} exact component={faqs} />
          <Route path={`${config.baseUrl}adminactivity`} exact component={adminactivity} />
          <Route path={`${config.baseUrl}userinfo/:user_id`} exact component={userinfo} />
          <Route path={`${config.baseUrl}transaction`} exact component={transaction} />
       </Switch>
      </div>
    </BrowserRouter>

  );
}

export default App;
