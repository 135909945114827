import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import config from '../config/config'
import Cookies from 'js-cookie';

export default class header extends Component {

  constructor(props) {
    super(props)
    this.state = {
         
    }
    this.loginData = (!Cookies.get('loginSuccessAdmin'))?[]:JSON.parse(Cookies.get('loginSuccessAdmin'))
}

componentDidMount() {
    
}

logout(){
    Cookies.remove('loginSuccessAdmin')
}


    render() {
        return (
  <>

<nav class="main-header navbar navbar-expand navbar-white navbar-light">
 
 <ul class="navbar-nav">
   <li class="nav-item">
     <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
   </li>
   {/* <li class="nav-item d-none d-sm-inline-block">
     <a href={`${config.baseUrl}dashboard`} class="nav-link">Home</a>
   </li> */}
   
 </ul>


 <ul class="navbar-nav ml-auto">
  
   {/* <li class="nav-item">
     <a class="nav-link" data-widget="navbar-search" href="#" role="button">
       <i class="fas fa-search"></i>
     </a>
     <div class="navbar-search-block">
       <form class="form-inline">
         <div class="input-group input-group-sm">
           <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search"/>
           <div class="input-group-append">
             <button class="btn btn-navbar" type="submit">
               <i class="fas fa-search"></i>
             </button>
             <button class="btn btn-navbar" type="button" data-widget="navbar-search">
               <i class="fas fa-times"></i>
             </button>
           </div>
         </div>
       </form>
     </div>
   </li> */}

   <li class="nav-item dropdown">
     <a class="nav-link" data-toggle="dropdown" href="#">
       <i class=""></i>
       <span class="badge badge-warning navbar-badge">Admin</span>
     </a>
     <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
      
    <div class="dropdown-divider"></div>
       <a href={`${config.baseUrl}changepassword`} class="dropdown-item">
         <i class="fas fa-key mr-2"></i>Change Password
      </a>
       <div class="dropdown-divider"></div>
       <a href={`${config.baseUrl}`} class="dropdown-item" onClick={this.logout}>
         <i class="fas fa-power-off"></i> Logout
      </a>
     </div>
   </li>

   {/* <li class="nav-item">
     <a class="nav-link" data-widget="fullscreen" href="#" role="button">
       <i class="fas fa-expand-arrows-alt"></i>
     </a>
   </li> */}

 </ul>
</nav>

</>
        )
    }

}