import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import Cookies from 'js-cookie';
import config from '../config/config'
import Sidebar from '../directives/sidebar'
import Header from '../directives/header'
import Footer from '../directives/footer'

export default class home extends Component {


  constructor(props) {
    super(props)
    this.state = {
        dashboard_list : ''
    }
}

componentDidMount() {
//   if(!Cookies.get('loginSuccess')){
//     window.location.href = `${config.baseUrl}`
//     return false;
//  }
  
  this.dashboardList();
}

async dashboardList() {
  await    axios.get(`${config.apiUrl}dashboardItem`, {}, )
          .then(result => {
              console.log(result.data);
              if (result.data.success === true) {
                  this.setState({
                      dashboard_list: result.data.response
                  })
               
              }

              else if (result.data.success === false) {

              }
          })

          .catch(err => {
          })
  }



    render() {
        return (
  <>

<div className="wrapper">


<div className="preloader flex-column justify-content-center align-items-center">
  <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60"/>
</div>

<Header/>
{/* --------------------------------------------------------------------------------------------------- */}
<Sidebar/>
<div className="content-wrapper">
<br/>

 
  <section className="content">
    <div className="container-fluid">
    
      <div className="row">
        <div className="col-lg-3 col-6">
        
          <div className="small-box bg-info">
            <div className="inner">
              <h3>{this.state.dashboard_list.deposits}</h3>

              <p>Deposits</p>
            </div>
            <div className="icon">
              <i className="ion ion-plus"></i>
            </div>
            <a href={`${config.baseUrl}transaction`} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>
   
        <div className="col-lg-3 col-6">
         
          <div className="small-box bg-success">
            <div className="inner">
              <h3>{this.state.dashboard_list.transfers}</h3>

              <p>Transfers</p>
            </div>
            <div className="icon">
              <i className="ion ion-stats-bars"></i>
            </div>
            <a href={`${config.baseUrl}transaction`} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>
    
        <div className="col-lg-3 col-6">
         
          <div className="small-box bg-warning">
            <div className="inner">
              <h3>{this.state.dashboard_list.user_count}</h3>

              <p>Users</p>
            </div>
            <div className="icon">
              <i className="ion ion-person-add"></i>
            </div>
            <a href={`${config.baseUrl}users`} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>
   
        <div className="col-lg-3 col-6">
          
          <div className="small-box bg-danger">
            <div className="inner">
              <h3>{this.state.dashboard_list.trx_count}</h3>

              <p>Transactions</p>
            </div>
            <div className="icon">
              <i className="ion ion-pie-graph"></i>
            </div>
            <a href={`${config.baseUrl}transaction`} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>
     
      </div>
     
      <div className="row">
          
      </div>
         </div>
  </section>
 
</div>

<Footer/>


<aside className="control-sidebar control-sidebar-dark">

</aside>

</div>
  </>

        )
    }
}





