import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import config from '../config/config'
import Sidebar from '../directives/sidebar'
import Header from '../directives/header'
import Cookies from 'js-cookie';
import ReactDatatable from '@ashvin27/react-datatable'
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import { Tabs, Tab } from 'react-tab-view'
import DataTable from 'react-data-table-component';

export default class userinfo extends Component {
    

    constructor(props) {
        super(props)
        this.state = {
            user_id: '',
            activitydetail: [],
            usertransactionlist: [],
            getwalletUSDbalance:[],
            getwalletTTDbalance:[],
            ip: '',
            browser:'',
            activity_time: '',
        }
        const { match: { params } } = this.props;
        this.user_id = params.user_id

        this.columns = [
            {
                key: '#',
                text: 'Sr. No.',
                sortable: true,
                cell: (row, index) => index + 1
              },
              {
                key: "ip",
                text: "IP",
                sortable: true
            },
            {
                key: "browser",
                text: "Browser",
                sortable: true
            },
           {
                key: "activity_time",
                text: "Activity Time",
                sortable: true
            },
           
         
          
          
        ];

       

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false
            }
        }


    }



    componentDidMount() {
        if (!Cookies.get('loginSuccessAdmin')) {
            window.location.href = `${config.baseUrl}`
            return false;
        }
       
        this.useractivity()
        this.usertransaction()
        this.walletbalance()
}



    async useractivity(user_id) {
   
    axios.post(`${config.apiUrl}userloginactivity`,{'user_id':this.user_id})
       .then(response => {
                  if (response.data.success === true) {
                    this.setState({
                activitydetail: response.data?.response,
                           })
          }
          else if (response.data.success === false) {
          }
       }).catch(err => {
       });
    }

    async usertransaction(user_id) {
       
        axios.post(`${config.apiUrl}transactionlist`,{'user_id':this.user_id})
           .then(response => {
                 if (response.data.success === true) {
               
                 this.setState({
                    usertransactionlist: response.data?.response,
                               })
              }
              else if (response.data.success === false) {
              }
           }).catch(err => {
           });
        }

        async walletbalance(user_id) {
          
            axios.post(`${config.apiUrl}getWalletBalance`,{'user_id':this.user_id})
               .then(response => {
              
                          if (response.data.success === true) {
                   
                     this.setState({
                        getwalletUSDbalance: response.data?.data[0],
                        getwalletTTDbalance: response.data?.data[1],

                                   })
                  }
                  else if (response.data.success === false) {
                  }
               }).catch(err => {
               });
            }
    


    render() {
       
            return (
      <>
<Header/>
{/* --------------------------------------------------------------------------------------------------- */}
<Sidebar/>
<div class="content-wrapper" style={{minHeight: '1170.12px'}}>
<section className="content">
      <div className="container-fluid">
      <br/>

<Tabs>
    <TabList>
      <Tab>Wallet</Tab>
      
      <Tab>Login Activity</Tab>
    </TabList>

    <TabPanel>
                <br/>
                
    <div className="row">
          <div className="col-lg-6 col-6">
            <div className="small-box bg-info">
              <div className="inner row">
              <div className="col-lg-6">
                <p style={{color: '#9cabff'}}>Available Balance in USD</p>
                <h3>$ {parseFloat(this.state.getwalletUSDbalance.balance).toFixed(2)}</h3>
                </div>
                <div className="col-lg-6">
                <p style={{color: '#9cabff'}}>Transaction</p>  
                <h3>{this.state.getwalletUSDbalance.trx_count}</h3>
              
                </div>
              </div>
           </div>
          </div>

           <div className="col-lg-6 col-6">
            <div className="small-box bg-info">
              <div className="inner row">
              <div className="col-lg-6">
                <p style={{color: '#9cabff'}}>Available Balance in TTD</p>
                <h3>TT$ {parseFloat(this.state.getwalletTTDbalance.balance).toFixed(2)}</h3>
                </div>
                <div className="col-lg-6">
                <p style={{color: '#9cabff'}}>Transaction</p>  
                <h3>{this.state.getwalletTTDbalance.trx_count}</h3>
              
                </div>
              </div>
           </div>
          </div>

          
        </div>
     <hr/>
      
     <h3>Transaction History</h3>
     <hr/>
                               <table className="table table-striped projects">
                                    <thead>

                                        <tr>
                                            <th style={{ width: '9%' }}>
                                                Order No.
                                            </th>
                                            <th style={{ width: '9%' }}>
                                              Transaction Type
                                            </th>
                                            <th style={{ width: '9%' }}>
                                                Amount($)
                                            </th>
                                            <th style={{ width: '9%' }}>
                                                Transaction Status
                                            </th>
                                            <th style={{ width: '9%' }}>
                                               Date and Time
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.usertransactionlist.map((item, i) => (
                                            <tr>
                                                <td>
                                                    {item.order_no}
                                                </td>
                                                <td>
                                                <span style={{paddingLeft:'22px'}}>{item.transaction_type}</span>
                                                </td>
                                                <td >
                                               <span style={{paddingLeft:'22px'}}> {parseFloat(item.amount).toFixed(2) }</span>
                                                </td>
                                                <td >
                                              

                                                {item.transaction_status === "Pending" ? <span style={{ color: '#ff9f00' }}>Pending</span> :
                                item.transaction_status === "Completed" ? <span style={{ color: 'green' }}>Completed</span> : 
                                    <span style={{ color: 'red' }}>Rejected</span>}
                                               </td>
                                              <td >
                                                {item.datetime}
                                               </td>
                                               

                                            </tr>))}
                                     

                                    </tbody>
                                </table>     
    </TabPanel>
    <br/>
    <TabPanel>
   

                 <ReactDatatable
                       config={this.config}
                       records={this.state.activitydetail}
                       columns={this.columns}
                                          /> 


    </TabPanel>
  </Tabs>
      
  </div>
  </section>
  </div>

  
 
 

</>
            )
    }

}