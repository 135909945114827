import React, { Component } from 'react';
import axios from 'axios';
import config from '../config/config'

import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
const headers = {
    'Content-Type': 'application/json'
 };

export default class forgotpassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }
    componentDidMount() {

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async submitForm(e) {
        e.preventDefault()
        // this.setState(initialState)
        const { email } = this.state;
        const data = this.state
        axios.post(`${config.apiUrl}/forgot`, data, { headers }, { email })
            .then(result => {
                if (result.data.success === true) {
                    toast.success(result.data.msg, {
                     
                    });
                    setTimeout(() => {
                                window.location.href = `${config.baseUrl}changepassword`
                    }, 2000);
                }
                // `${config.baseUrl}login`
                else if (result.data.success === false) {
                    toast.error(result.data.msg, {
                        
                    });
                }
            })

            .catch(err => {
                if (err.request) { } if (err.response) {
                    toast.error(err.response.data.msg, {
                        
                    });
                }
            });
     
    }

    render() {
        return (
  <>

  
  <div className="hold-transition login-page">
<div className="login-box">
  <div className="login-logo">
    <a href="../../index2.html"><b>FREE</b>UP</a>
    <h5 style={{color:"#ff5722"}}>Free Your Pain</h5>
  </div>
  
  <div className="card">
    <div className="card-body login-card-body">
      <p className="login-box-msg"> Generate Your Password</p>

      <form  onSubmit={this.submitForm}>
      <Toaster
  position="top-center"
  reverseOrder={false}
/>
        <div className="input-group mb-3">
          <input type="email" className="form-control" placeholder="Email"  value={this.state.email}
                                                    onChange={this.onChange} name="email"/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-envelope"></span>
            </div>
          </div>
        </div>
       
     
        <div className="social-auth-links text-center mb-3">
      
      <button type="submit" className="btn btn-block btn-primary">Request Resent Link
      </button>
     
    </div>
      </form>

     
  

    
     
    </div>
   
  </div>
</div>
</div>
  </>

        )
    }
}