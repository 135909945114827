import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import config from '../config/config'
import Sidebar from '../directives/sidebar'
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie';
import ReactDatatable from '@ashvin27/react-datatable'
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class transaction extends Component {

    constructor(props) {
        super(props)
        this.state = {
            usertransactionlist : [],
            data: [],
            index : 0
                    };
        this.loginData = (!Cookies.get('loginSuccessAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessAdmin'));
  

        this.columns = [
            {
                key: '#',
                text: 'Sr. No.',
             
                cell: (row, index) => index + 1
              },
              {
                key: "order_no",
                text: "Order No",
              
            },
            {
              key: "transaction_type",
              text: "Transaction Type",
              cell: (item) => {
                  return (
                      <p style={{paddingLeft:'22px'}}>{item.transaction_type}</p>
                  );
              }
          },
            {
                key: "transaction_status",
                text: "Transaction Status",
               
                cell: (item) => {
                    return (
                        <>
                        <span style={{paddingLeft:'22px'}}>
                              {item.transaction_status === "Pending" ? <span style={{ color: '#ff9f00' }}>Pending</span> :
                                item.transaction_status === "Completed" ? <span style={{ color: 'green' }}>Completed</span> : 
                                    <span style={{ color: 'red' }}>Rejected</span>}
                             </span>
                        </>
                    );
                }
            },
            {
                key: "datetime",
                text: "Date and Time",
              
            },
          
          
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false
            }
        } 
       }


       componentDidMount() {
        if (!Cookies.get('loginSuccessAdmin')) {
            window.location.href = `${config.baseUrl}`
            return false;
        }
              this.usertransaction()      
}


       async usertransaction(user_id) {
       
        axios.post(`${config.apiUrl}transactionlist`,{'user_id':0})
           .then(response => {
                 if (response.data.success === true) {
               
                 this.setState({
                    usertransactionlist: response.data?.response,
                               })
              }
              else if (response.data.success === false) {
              }
           }).catch(err => {
           });
        }




    render() {
       
        return (
  <>
<Header/>

<Sidebar/>
<div class="content-wrapper" >
<section className="content">
      {/* <div className="container-fluid"> */}


<div className="card">
  <div className="card-header">
    <h3 className="card-title">All User Transaction</h3>
   <br/>
    <hr/>
  
  <div className="card-body p-0">
    <table className="table table-striped projects table-responsive-sm">
      
        
        <ReactDatatable
                       config={this.config}
                       records={this.state.usertransactionlist}
                       columns={this.columns}
                                          /> 
        
    </table>
    </div>
  </div>
</div>
{/* </div> */}
</section>
</div>

<Footer/>

  </>
        )
    }

}