import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import config from '../config/config'
import Sidebar from '../directives/sidebar'
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie';
import ReactDatatable from '@ashvin27/react-datatable'
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const headers = {
  'Content-Type': 'application/json'
};


export default class contactus extends Component {

  constructor(props) {
    super(props)
    this.state = {
      questions: [],
      answers: [],
      pain_question: '',
      pain_answer: '',
      answerParticular:'',
      selectedAnswers:[]

    }
    this.onChange = this.onChange.bind(this)
    this.editDataAPI = this.editDataAPI.bind(this);
    this.updatePainQandA = this.updatePainQandA.bind(this)

    this.columns = [
      {
        key: '#',
        text: 'Sr. No.',
        sortable: true,
        cell: (row, index) => index + 1
      },
      {
        key: "name",
        text: "Name",
        sortable: true
      },
      {
        key: "email",
        text: "Email",
        sortable: true
      },
      {
        key: "subject",
        text:"Subject",
        sortable: true
      },
    
     
      {
        key: "comments",
        text: "Comment",
        sortable: true
      },
 
    


    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      show_filter: true,
      show_pagination: true,
      pagination: 'advance',
      button: {
        excel: false,
        print: false
      }
    }
  }

  onChange1 = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  };

  painAnswer(id){
    console.log(id.answer);
    this.setState({
      answerParticular:id.answer
    })
  }


  onChange = event => {
    event.getPain();

    let value = event.target.value;

    this.setState(prevState => ({
      questionandanswer: { ...prevState.questionandanswer, [event.target.name]: value }
    }))
  };

  componentDidMount() {
    if (!Cookies.get('loginSuccessAdmin')) {
      window.location.href = `${config.baseUrl}`
      return false;
  }
    this.getPainQandA();
    
  }


  getPainQandA = async e => {

    await axios.get(`${config.apiUrl}/getAllContactList`, {})
      .then(result => {

        if (result.data.success === true) {
          this.setState({

            questions: result.data.response
          })
        }
      })
  }

  // ====================================Pain Answers==============================================

  async getPainAnswers(item) {
    var ans = JSON.parse(item.answer);
    console.log(ans)
    this.setState({
      selectedAnswers:ans
    })
}

editDataAPI(id){
   
  this.setState({
    
   answer : id.answer,
   id:id.id,
     
 }); 

}
  // ==============================================deletePain=============================

  async deletePain(id) {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to delete this Pain..',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            axios.post(`${config.apiUrl}/deletepainqanda`, { id: id.id })
              .then(result => {

                toast.success(result.data.msg, {

                });
                this.painList();
              }).catch((error) => {
                toast.danger(error.data.msg, {

                });
              })
        },
        {
          label: 'No',
        }
      ]
    });
  }






  async updatePainQandA(id) {
    // console.log(id);
    var data = {
      pain_question: this.state.pain_question,
      pain_answer: this.state.pain_answer
    }

    axios.post(`${config.apiUrl}/updatepainque&ans`, data, { id: id.id, pain_question: this.state.pain_question, pain_answer: this.state.pain_answer })
      .then(response => {

        if (response.data.success === true) {
          toast.success(response.data.msg)
          // setTimeout(() => {
          //     window.location.href = `${config.baseUrl}`

          // }, 2000);
        }

        else if (response.data.success === false) {

        }
      })

      .catch(err => {
        toast.error(err.response.data?.msg);
      })
  }


  render() {
    return (
      <>
        <Header />
        {/* --------------------------------------------------------------------------------------------------- */}
        <Sidebar />
        <div className="content-wrapper">
          <div class="card-header">
          

            <div class="btn-group">
            </div> 
          
          <br />
          <table className="table table-striped projects table-responsive-sm">
          <ReactDatatable
            config={this.config}
            records={this.state.questions}

            columns={this.columns}
          />
         </table>
          </div>     
        </div>
        <Footer/>
      </>

    )
  }
}