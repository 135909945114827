import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import Cookies from 'js-cookie';
import config from '../config/config'

export default class sidebar extends Component {

  constructor(props) {
    super(props)
   

    this.loginData = (!Cookies.get('loginSuccessAdmin'))?[]:JSON.parse(Cookies.get('loginSuccessAdmin'))
 
}


    
      
    render() {
        return (
  <>

<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <a href={`${config.baseUrl}dashboard`} class="brand-link">
    <img src="dist/img/ABC.jpeg" alt="AdminLTE Logo" class="brand-image  " style={{opacity: ".8",maxHeight:"85px",width:"60px"}}/>
    <span class="brand-text font-weight-light">Wallet</span>
  </a>
<br/>


  <div class="sidebar">
  
  

  
    
   
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
    
        <li class="nav-item menu-open">
          <ul class="nav nav-treeview">
          <li class="nav-item">
              <a href={`${config.baseUrl}dashboard`} class="nav-link">
                <i class="fas fa-tachometer-alt nav-icon"></i>
               
                <p>Dashboard</p>
              </a>
            </li>
            <li class="nav-item">
              <a href={`${config.baseUrl}users`} class="nav-link">
                <i class="far fa-user nav-icon"></i>
                <p>Users</p>
              </a>
            </li>
            <li class="nav-item">
              <a href={`${config.baseUrl}contactus`} class="nav-link">
                <i class="fas fa-comment-alt nav-icon"></i>
                <p>Contact Us</p>
              </a>
            </li>
            <li class="nav-item">
              <a href={`${config.baseUrl}faqs`} class="nav-link">
                <i class="far fa-calendar-plus nav-icon"></i>
                <p>FAQ </p>
              </a>
            </li>
            <li class="nav-item">
              <a href={`${config.baseUrl}adminactivity`} class="nav-link">
                <i class="fas fa-user-secret nav-icon"></i>
                <p>Admin Activity</p>
              </a>
            </li>
            <li class="nav-item">
              <a href={`${config.baseUrl}transaction`} class="nav-link">
                <i class="fas fa-money-bill nav-icon"></i>
                <p>Transaction</p>
              </a>
            </li>
           
          
          </ul>
        </li>
      
      </ul>
    </nav>
   
  </div>

</aside>


  </>
        )
    }
}