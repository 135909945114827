import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import Cookies from 'js-cookie';
import config from '../config/config'

export default class footer extends Component {

  constructor(props) {
    super(props)
   

    this.loginData = (!Cookies.get('loginSuccessAdmin'))?[]:JSON.parse(Cookies.get('loginSuccessAdmin'))
 
}


    
      
    render() {
        return (
  <>
<footer className="main-footer">
  <strong>Copyright &copy; 2014-2021 <a href="https://espsofttech.tech/abc-wallet/" target="_blank">ABC Wallet </a>.</strong>
  All rights reserved.
  
</footer>

</>

        )
    }
}